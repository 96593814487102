<div class="modal-header">
  <h4 class="modal-title">Case Details</h4>
  <div class="ss-form-close-icon d-flex flex-row" style="gap: 15px">
    <div
      (click)="DownloadDetails(selectedItem[0].case_id)"
      class="ss-form-close-icon"
      style="cursor: pointer"
    >
      <i class="fas fa-download"></i>
    </div>
    <div class="ss-form-close-icon" (click)="onCancelClick()">
      <img src="assets/images/ss-icons/form-cross.svg" alt="" />
    </div>
  </div>
  <!-- <div class="ss-form-close-icon" (click)="onCancelClick()">
        <img src="assets/images/ss-icons/form-cross.svg" alt="">
    </div> -->
</div>
<div class="modal-body">
  <div class="container-fluid" *ngIf="selectedItem == undefined">
    <div class="row">
      There is something wrong, hence unable to fetch Case Details at present.
      Kindly contact Support Team.
    </div>
  </div>
  <div class="container-fluid" id="content" *ngIf="selectedItem">
    <div class="row" *ngIf="selectedItem[0].case_type == 'company'">
      <div class="form-secondary-text">Worksite Details</div>
      <div class="content-stat-devider mt-2"></div>
      <div class="d-flex flex-row justify-item-start align-items-center mt-3">
        <div
          class="col info-item d-flex flex-column justify-item-start align-items-start gap-1"
        >
          <div class="form-section-title">Project Code</div>
          <div class="form-secondary-text">
            {{ selectedItem[0].project_code }}
          </div>
        </div>
        <div
          class="col info-item d-flex flex-column justify-item-start align-items-start gap-1"
        >
          <div class="form-section-title">Project Name</div>
          <div class="form-secondary-text">
            {{ utils.capitalizeName(selectedItem[0].project_name) }}
          </div>
        </div>
        <div
          class="col info-item d-flex flex-column justify-item-start align-items-start gap-1"
        >
          <div class="form-section-title">Site ID</div>
          <div class="form-secondary-text">{{ selectedItem[0].siteid }}</div>
        </div>
        <div
          class="col info-item d-flex flex-column justify-item-start align-items-start gap-1"
        >
          <div class="form-section-title">Contact</div>
          <div class="form-secondary-text">
            {{ selectedItem[0].contact_number }}
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="form-secondary-text">Case Details</div>
      <div class="content-stat-devider mt-2"></div>
      <div class="d-flex flex-row justify-item-start align-items-center mt-3">
        <div
          class="col info-item d-flex flex-column justify-item-start align-items-start gap-1"
        >
          <div class="form-section-title">Case ID</div>
          <div class="form-secondary-text">{{ selectedItem[0].case_id }}</div>
        </div>
        <div
          class="col info-item d-flex flex-column justify-item-start align-items-start gap-1"
        >
          <div class="form-section-title">Status</div>
          <div class="form-secondary-text">
            {{ utils.capitalizeName(selectedItem[0].status) }}
          </div>
        </div>
        <div
          class="col info-item d-flex flex-column justify-item-start align-items-start gap-1"
        >
          <div class="form-section-title">Category</div>
          <div class="form-secondary-text">
            {{ selectedItem[0].case_category }}
          </div>
        </div>
        <div
          class="col info-item d-flex flex-column justify-item-start align-items-start gap-1"
        >
          <div class="form-section-title">Location</div>
          <div class="form-secondary-text">
            {{ selectedItem[0].case_location }}
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="d-flex flex-row justify-item-start align-items-center">
        <div
          *ngIf="selectedItem[0].case_type !== 'public'"
          class="col info-item d-flex flex-column justify-item-start align-items-start gap-1"
        >
          <div class="form-section-title">Case Deadline</div>
          <div class="form-secondary-text">
            {{ selectedItem[0].case_deadline }}
          </div>
        </div>
        <div
          class="col info-item d-flex flex-column justify-item-start align-items-start gap-1"
        >
          <div class="form-section-title">Created At</div>
          <div class="form-secondary-text">
            {{ utils.getSGTZoneDateTime(selectedItem[0].created_at) }}
          </div>
        </div>
        <div
          class="col-6 info-item d-flex flex-column justify-item-start align-items-start gap-1"
        >
          <div class="form-section-title">Description</div>
          <div class="form-secondary-text">
            {{ selectedItem[0].case_description }}
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3" *ngIf="images.length > 0; else noMedia">
      <!-- <div style="width:314px; height:44px"> -->
      <ng-image-slider
        [images]="images"
        [imageSize]="imageSize"
        [manageImageRatio]="true"
        [showArrow]="false"
        [infinite]="true"
        [animationSpeed]="2"
        [autoSlide]="false"
        #nav
      ></ng-image-slider>
      <!--  </div>   -->
    </div>
    <ng-template #noMedia>
      <div>Case media is unavailable</div>
    </ng-template>
    <div class="row mt-3">
      <div class="form-secondary-text">Assignee Details</div>
      <div class="content-stat-devider mt-2"></div>
      <div class="d-flex flex-row justify-item-start align-items-center mt-3">
        <div
          class="col info-item d-flex flex-column justify-item-start align-items-start gap-1"
        >
          <div class="form-section-title">Assignee ID</div>
          <div class="form-secondary-text">
            {{ selectedItem[0].assigned_to }}
          </div>
        </div>
        <div
          class="col info-item d-flex flex-column justify-item-start align-items-start gap-1"
        >
          <div class="form-section-title">Assignee Name</div>
          <div class="form-secondary-text">{{ selectedItem[0].name }}</div>
        </div>
        <div
          class="col info-item d-flex flex-column justify-item-start align-items-start gap-1"
        >
          <div class="form-section-title">Assignee Role</div>
          <div class="form-secondary-text">
            {{ utils.getMappedRoleDisplayName(selectedItem[0].role) }}
          </div>
        </div>
        <div
          class="col info-item d-flex flex-column justify-item-start align-items-start gap-1"
        >
          <div class="form-section-title">Assignee Email</div>
          <div class="form-secondary-text">{{ selectedItem[0].email }}</div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="mt-5">
        <p-timeline
          [value]="selectedItem"
          align="alternate"
          styleClass="customized-timeline"
        >
          <ng-template pTemplate="marker" let-event>
            <span class="custom-marker p-shadow-2">
              <!--   <i class="fas fa-cog fa-fw"></i> --><i class="fas"></i>
              <i
                class="fas"
                [ngClass]="{
                  'fa-exclamation-circle unassigned':
                    event.t_status == 'unassigned',
                  'fa-hourglass-half inprogress':
                    event.t_status == 'inprogress',
                  'fa-lock-open open': event.t_status == 'open',
                  'fa-check-circle resolved': event.t_status == 'resolved',
                  'fa-times-circle rejected': event.t_status == 'rejected'
                }"
              >
              </i>
            </span>
          </ng-template>
          <ng-template pTemplate="content" let-event>
            <p-card
              [header]="utils.capitalizeName(event.t_status)"
              [subheader]="utils.getSGTZoneDateTime(event.t_updated_at)"
            >
              <div class="text-left">
                <div class="data-label">Reason</div>
                <div
                  *ngIf="event.t_status !== 'reject'"
                  class="form-secondary-text"
                >
                  {{ event.t_status_reason ? event.t_status_reason : "NA" }}
                </div>
                <div
                  *ngIf="event.t_status === 'reject'"
                  class="form-secondary-text"
                >
                  {{
                    event.t_status_description
                      ? event.t_status_description
                      : "NA"
                  }}
                </div>
              </div>
              <div
                class="row mt-3"
                *ngIf="
                  resolvedCaseMedia !== null &&
                  resolvedCaseMedia.length > 0 &&
                  event.t_status === 'resolved'
                "
              >
                <ng-image-slider
                  [images]="resolvedCaseMedia"
                  [imageSize]="imageSize"
                  [manageImageRatio]="true"
                  [showArrow]="false"
                  [infinite]="true"
                  [animationSpeed]="2"
                  [autoSlide]="false"
                  #navResolved
                ></ng-image-slider>
              </div>
            </p-card>
          </ng-template>
        </p-timeline>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn ss-btn-secondary" (click)="onCancelClick()">
    Close
  </button>
  <!--  <button type="button" class="btn ss-btn-primary" (click)="onConfirmClick()">Assign</button> -->
</div>
