import { Component, OnInit } from '@angular/core';
import { HttpService } from './services/http/http.service';
import { UtilsService } from './services/utils/utilis.service';
import { FcmService } from './services/fcm/fcm.service';
import { DashboardStatisticsService } from './services/data/dashboard-statistics/dashboard-statistics.service';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{

  constructor(private http: HttpService, 
    private utils: UtilsService, 
    private fcmService: FcmService,
    private dashboardDataService: DashboardStatisticsService,
    private router: Router){
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          if (event.url === '/login') {
            // localStorage.clear();  
          }
        }
      });
    }
  ngOnInit(): void {
     this.fcmService.receiveMessages();
     // Initialize socket conection for Dashboard data
              // (1). Client Dashboard data
                //const user = this.utils.getLoggedInUserDetails();
               //this.dashboardDataService.getClientDashboardStatistics(user);
  }
  title = 'Safety Sheild';
}
