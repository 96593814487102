import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpService } from 'src/app/services/http/http.service';

@Component({
  selector: 'app-request-delete-user',
  templateUrl: './request-delete-user.component.html',
  styleUrls: ['./request-delete-user.component.scss'],
})
export class RequestDeleteUserComponent {
  deleteAccountForm: any;
  isFormSubmitted: boolean = false;
  // uid: string | null = null;
  // operation: string | null = null;
  // email: string | null = null;

  ngOnInit(): void {
    this.deleteAccountForm = this.fb.group({
      email: [
        null,
        [
          Validators.required,
          Validators.pattern(
            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
          ),
        ],
      ],
    });
  }

  constructor(
    private fb: FormBuilder,
    private http: HttpService,
    private router: Router
  ) {}
  /****
   * Go to login Screen
   */
  /* goToLogin(){
    this.router.navigate(['/login']);
  } */
  /*********************
   * On Submit
   *********************/
  /**
   * On Submit Form
   */
  onSubmit() {
    if (this.deleteAccountForm.valid) {
      console.log(
        'deleteAccountForm Values >>>>> ',
        this.deleteAccountForm.value
      );
      let payload = {
        requester_email: this.deleteAccountForm.value['email'],
      };
      console.log('deleteAccount Payload >>>>> ', payload);
      // http request
      let httpHeaders = new HttpHeaders().append('request-from', 'web');
      this.http.post('deleteAccount', payload, httpHeaders).subscribe(
        (res) => {
          console.log('deleteAccount API Response >>>>> ', res);
          this.isFormSubmitted = true;
          /* localStorage.setItem(
            'forget-password-email',
            this.deleteAccountForm.value['email']
          ); */
        },
        (err) => {
          this.isFormSubmitted = false;
        }
      );
    }
  }
}
