// loader.component.ts
import { Component, OnInit } from '@angular/core';
import { WaitingLoaderService } from '../../waiting-loader.service';
// import { LoaderService } from './loader.service';

@Component({
  selector: 'app-loader',
  template: `
    <div *ngIf="isLoading" class="overlay">
    <div class="loading">
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
      </div>
    </div>
  `,
  styles: [`
    .overlay {
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgb(0 0 0 / 61%);
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 999999;
    }

    .loading {
  $colors: #7ef9ff, #89cff0, #4682b4, #0f52ba, #000080;
  display: flex;

  .dot {
    position: relative;
    width: 2em;
    height: 2em;
    margin: 0.8em;
    border-radius: 50%;

    &::before {
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      background: inherit;
      border-radius: inherit;
      animation: wave 2s ease-out infinite;
    }

    @for $i from 1 through 5 {
      &:nth-child(#{$i}) {
        background: nth($colors, $i);

        &::before {
          animation-delay: $i * 0.2s;
        }
      }
    }
  }
}

@keyframes wave {
  50%,
  75% {
    transform: scale(2.5);
  }

  80%,
  100% {
    opacity: 0;
  }
}
    
  `],
})
export class LoaderComponent implements OnInit {
  isLoading: boolean | undefined;

  constructor(private loaderService: WaitingLoaderService) {}

  ngOnInit() {
    this.loaderService.isLoading$Observable().subscribe((isLoading) => {
      this.isLoading = isLoading;
    });
  }
}

