import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { HttpService } from '../../http/http.service';

import { UtilsService } from '../../utils/utilis.service';
import { HSEFInfoModalService } from '../HSEFInfoModal.service';

@Component({
  selector: 'app-hsef-info-modal',
  templateUrl: './hsef-info-modal.component.html',
  styleUrls: ['./hsef-info-modal.component.scss']
})
export class HsefInfoModalComponent {
  @Input() data: any;
  // @Output() confirmEvent = new EventEmitter<any>();
  wshaList: any[] = [];
  selectedWSHA: any;
  selectedItem: any;

  confirmCallback: () => void = () => {};
  cancelCallback: () => void = () => {};

  constructor(public bsModalRef: BsModalRef, 
              private http: HttpService, 
              public utils: UtilsService) {}
  ngOnInit(): void {
    this.selectedItem = this.data[0]['HSEF_REGISTRY_DETAILS'];
  }

  onCancelClick(): void {
    this.bsModalRef.hide();
  }
}
