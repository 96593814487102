// action-renderer.component.ts
import { HttpHeaders } from '@angular/common/http';
import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { AssignCaseDialogService } from 'src/app/services/assign-case-dialog/assign-case-dialog.service';
import { HSEFInfoModalService } from 'src/app/services/hsef-info-modal/HSEFInfoModal.service';

import { HttpService } from 'src/app/services/http/http.service';
import { UtilsService } from 'src/app/services/utils/utilis.service';

@Component({
  selector: 'app-action-renderer',
  template: `
    <div class="d-flex flex-row gap-1 align-items-center justify-content-start">
      <div class="ss-grid-actions" (click)="showDetails()">
        <i class="fas fa-eye"></i> View
      </div>
      |
      <div class="ss-grid-actions" (click)="BadgetoUser()">
        <i class="fas fa-star"></i> Badge
      </div>
      |
      <div class="ss-grid-actions" (click)="BlacklistUser()">
        <i class="fas fa-minus-circle"></i>
        {{ this.rowData['is_blacklisted'] ? 'Whitelist' : 'Blacklist' }}
      </div>
      |
      <div class="ss-grid-actions" (click)="DeleteUser()">
        <i class="fas fa-trash"></i> Remove
      </div>
    </div>
  `,
  styles: [
    `
      .ss-grid-actions {
        color: #000a89;
        font-size: 12px;
        cursor: pointer;
      }
      .ss-grid-actions:hover {
        color: #1b28ca;
        transition: 0.9s;
      }
    `,
  ],
})
export class HSEFGridActionComponent implements ICellRendererAngularComp {
  rowData: any;
  refreshGrid: any;
  wshaList: any = [];
  hsefDetails: any;
  allDetails: any;

  /* inputDataModel = {
        caseDetails: undefined, wshaDetails: undefined
    }; */
  constructor(
    private hsefDetailsModalService: HSEFInfoModalService,
    private http: HttpService,
    private utils: UtilsService
  ) {}

  agInit(params: ICellRendererParams): void {
    this.rowData = params.data;
    this.refreshGrid = params.colDef?.cellRendererParams.refreshHSEFAGrid;

    // get-case-details-by-id
    //let formData = new FormData();
    //   formData.append('case_id', this.rowData['case_id']);
  }
  refresh(params: ICellRendererParams<any, any, any>): boolean {
    return true;
  }

  isUnassigned(): boolean {
    return this.rowData?.status === 'unassigned';
  }

  showDetails() {
    if (this.rowData['status'] !== 'unassigned') {
      this.http
        .post('get-hsef-registry-details', {
          hsef_reg_id: this.rowData['hsef_reg_id'],
        })
        .subscribe((res) => {
          this.hsefDetails = res['data'];
          this.hsefDetails[0]['HSEF_REGISTRY_DETAILS'][0]['imageUrl'] =
            this.rowData.imageUrl;
          // once case details are available then open model dialog
          this.hsefDetailsModalService
            .open(this.hsefDetails)
            .subscribe((selectedItem) => {
              // do something once dialog opened
            });
        });
    }
  }

  DeleteUser() {
    if (this.rowData['status'] !== 'unassigned') {
      this.http
        .post('delete-hsef-registry', {
          hsef_reg_id: this.rowData['hsef_reg_id'],
        })
        .subscribe((res) => {
          console.log(res);
          this.refreshGrid();
        });
    }
  }

  BadgetoUser() {
    let _this = this;
    if (this.rowData['status'] !== 'unassigned') {
      this.http
        .post('update-hsef-badge', {
          hsef_reg_id: this.rowData['hsef_reg_id'],
        })
        .subscribe((res) => {
          console.log(res);
          _this.refreshGrid();
        });
    }
  }

  BlacklistUser() {
    if (this.rowData['is_blacklisted'] == null) {
      this.http
        .post('blacklist-hsef-user', {
          hsef_reg_id: this.rowData['hsef_reg_id'],
        })
        .subscribe((res) => {
          console.log(res);
          this.refreshGrid();
        });
    } else {
      this.http
        .post('whitelist-hsef-user', {
          hsef_reg_id: this.rowData['hsef_reg_id'],
        })
        .subscribe((res) => {
          console.log(res);
          this.refreshGrid();
        });
    }
  }
}
