import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpService } from './services/http/http.service';
import { WaitingLoaderService } from './services/waiting-loader/waiting-loader.service';
import { LocalStorageService } from './services/local-storage/local-storage.service';
import { ActiveMenuService } from './services/menu/active-menu.service';
import { AgGridModule } from 'ag-grid-angular';
import { SidePanelService } from './services/side-panel/side-panel.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { HttpErrorInterceptor } from './services/interceptor/HttpErrorInterceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoaderComponent } from './services/waiting-loader/loader/loader/loader.component';
import { MultilineTrendGraphComponent } from './components/graphs/multiline-trend-graph/multiline-trend-graph.component';
import { DonutGraphComponent } from './components/graphs/donut-graph/donut-graph.component';
import { GraphsModule } from './components/graphs/graphs.module';
import { ConfirmDialogComponent } from './services/confirmation-dialog/dialog/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogService } from './services/confirmation-dialog/confirm-dialog.service';
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { AssignCaseDialogComponent } from './services/case-assignment-dialog/dialog/assign-case-dialog.component';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ManagePublicCaseGridActionComponent } from './components/grid-actions/manage-public-cases';
import { CaseInfoModalComponent } from './services/case-info-modal/case-info-modal/case-info-modal.component';
import { AssignCaseComponent } from './services/assign-case-dialog/assign-case/assign-case.component';
import { TimelineModule } from 'primeng/timeline';
import { CardModule } from 'primeng/card';
import { HsefInfoModalComponent } from './services/hsef-info-modal/hsef-info-modal/hsef-info-modal.component';
import { NgImageSliderModule } from 'ng-image-slider';
import { RouterModule } from '@angular/router';
import { PanelModule } from 'primeng/panel';
import { TbmInfoModalComponent } from './services/tbm-info-modal/tbm-info-modal.component';
import { UserMenuComponent } from './components/ui-partials/user-menu/user-menu.component';
import { FcmService } from './services/fcm/fcm.service';

// Import the functions you need from the SDKs you need
import { provideFirebaseApp, getApp, initializeApp } from '@angular/fire/app';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { DashboardStatisticsService } from './services/data/dashboard-statistics/dashboard-statistics.service';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { UtilsService } from './services/utils/utilis.service';
import { environment } from './../environments/environment';
import { ImageModule } from 'primeng/image';
import { HSEFGridActionComponent } from './components/grid-actions/manage-hsef.actions';
import { PlanLimitAlertComponent } from './services/plan-limit-alert/plan-limit-alert.component';
import { MatDialogModule } from '@angular/material/dialog';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyBdGO2SW8TxN5RdGaXnN9-iGo8MjpzMquY',
  authDomain: 'snap-at-champion.firebaseapp.com',
  projectId: 'snap-at-champion',
  storageBucket: 'snap-at-champion.appspot.com',
  messagingSenderId: '743479782021',
  appId: '1:743479782021:web:1556419a9d88d8439397ec',
  measurementId: 'G-D4GR2498JS',
};

const getAppOrigin = () => {
  let originUrl = environment.apiUrl;
  return originUrl.replace('/v1/api', '');
};

const socketConfig: SocketIoConfig = { url: getAppOrigin(), options: {} };

// Initialize Firebase
/* const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app); */

@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent,
    ConfirmDialogComponent,
    AssignCaseDialogComponent,
    ManagePublicCaseGridActionComponent,
    CaseInfoModalComponent,
    AssignCaseComponent,
    HsefInfoModalComponent,
    TbmInfoModalComponent,
    HSEFGridActionComponent,
    PlanLimitAlertComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    ModalModule.forRoot(),
    TypeaheadModule.forRoot(),
    TimelineModule,
    CardModule,
    NgImageSliderModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireMessagingModule,
    SocketIoModule.forRoot(socketConfig),
    ImageModule,
    MatDialogModule,
    // provideFirebaseApp(() => initializeApp(firebaseConfig)),
  ],
  providers: [
    HttpService,
    WaitingLoaderService,
    LocalStorageService,
    ActiveMenuService,
    SidePanelService,
    BsModalService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    FcmService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
