export class LoggedInUserModel{
    company_id: string | undefined;
    user_id: string | undefined;
    role: string | undefined;
    name: string | undefined;
    username: string | undefined;
    email: string | undefined;
    deleted: boolean | undefined;
    status: string | undefined;
    profile_picture: string | undefined;
}