<div
  class="container-fluid pg-bg d-flex justify-content-center align-items-center fh-100"
>
  <div class="login-form-row">
    <div class="left-col"></div>
    <div class="right-col">
      <div
        class="d-flex flex-column align-items-center justify-content-center p-5"
        *ngIf="!isFormSubmitted"
      >
        <form [formGroup]="deleteAccountForm" (ngSubmit)="onSubmit()">
          <div class="container-fluid">
            <div class="row">
              <div
                class="d-flex flex-row align-items-center justify-content-center p-4"
              >
                <img
                  src="assets/images/login-page/logo.png"
                  class="nav-branding"
                  alt=""
                />
              </div>
            </div>
            <div class="row">
              <div
                class="login-form-header d-flex flex-row align-items-center justify-content-center mt-3"
              >
                Delete Account?
              </div>
            </div>
            <div class="row">
              <div
                class="login-form-sub-content-text d-flex flex-row align-items-center justify-content-center mt-3"
              >
                We're sorry to see you leaving.
              </div>
            </div>
            <div class="row mt-4">
              <div
                class="d-flex flex-column justify-content-center align-items-center gap-4"
              >
                <div class="ss-form-item ss-auto-width">
                  <label for="email">Email ID</label>
                  <input
                    type="email"
                    class="form-control ss-form-control shadow-none"
                    name="email"
                    aria-label=""
                    formControlName="email"
                    [ngClass]="{
                      'ss-invalid-field':
                        deleteAccountForm?.get('email')?.invalid &&
                        deleteAccountForm?.get('email')?.touched
                    }"
                    required
                  />
                </div>
              </div>
            </div>
            <!-- Action Button -->
            <div class="row mt-3">
              <div
                class="d-flex flex-row justify-content-center align-items-center mt-3 gap-3"
              >
                <button
                  type="submit"
                  class="btn ss-btn-primary"
                  [disabled]="!deleteAccountForm.valid"
                >
                  Delete Account Request
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>

      <!--Message Response-->
      <div
        class="d-flex flex-column align-items-center justify-content-center p-5"
        *ngIf="isFormSubmitted"
      >
        <div class="container-fluid">
          <div class="row">
            <div
              class="login-form-header d-flex flex-row align-items-center justify-content-center mt-3"
            >
              Request sent, we'll get back to you shortly.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
