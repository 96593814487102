import { Injectable } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Observable, Subject } from 'rxjs';
import { TbmInfoModalComponent } from './tbm-info-modal.component';

@Injectable({
  providedIn: 'root',
})
export class TBMInfoModalService {
  private modalRef: BsModalRef | undefined;
  private selectedRowData$ = new Subject<any>();

  constructor(private modalService: BsModalService) {}
  open(data: any): Observable<any> {
    const initialState = { data, selectedRowData$: this.selectedRowData$ };
    this.modalRef = this.modalService.show(TbmInfoModalComponent, {
      class: 'modal-dialog-centered modal-lg',
      initialState,
    });

    return this.selectedRowData$.asObservable();
  }

  close(): void {
    if (this.modalRef) {
      this.modalRef.hide();
    }
  }

  setSelectedRowData(selectedTBM: any): void {
    this.selectedRowData$.next(selectedTBM);
  }
}
