// confirm-dialog.service.ts

import { Injectable } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
// import { AssignCaseDialogComponent } from '../case-assignment-dialog/dialog/assign-case-dialog.component';
import { Observable, Subject } from 'rxjs';
import { AssignCaseComponent } from './assign-case/assign-case.component';


@Injectable({
    providedIn: 'root',
  })
  export class AssignCaseDialogService {
    private modalRef: BsModalRef | undefined;
    private selectedWSHA$ = new Subject<any>();

  constructor(private modalService: BsModalService) {}
  open(data: any, rowData: any): Observable<any> {
    const initialState = { data, rowData, selectedWSHA$: this.selectedWSHA$ };
    this.modalRef = this.modalService.show(AssignCaseComponent, {
      class: 'modal-dialog-centered modal-lg',
      initialState,
    });

    return this.selectedWSHA$.asObservable();
  }

  close(): void {
    if (this.modalRef) {
      this.modalRef.hide();
    }
  }

  setSelectedWSHA(selectedWSHA: any): void {
    this.selectedWSHA$.next(selectedWSHA);
  }
  }
