import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WaitingLoaderService {

  constructor() { }
  private isLoading$ = new BehaviorSubject<boolean>(false);

  get isLoading(): boolean {
    return this.isLoading$.value;
  }

  isLoading$Observable() {
    return this.isLoading$.asObservable();
  }

  show() {
    this.isLoading$.next(true);
  }

  hide() {
    this.isLoading$.next(false);
  }
}
