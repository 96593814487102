<div class="modal-header">
  <h4 class="modal-title">Assign Case</h4>
  <div class="ss-form-close-icon" (click)="onCancelClick()">
    <img src="assets/images/ss-icons/form-cross.svg" alt="" />
  </div>
</div>
<div class="modal-body">
  <div class="container-fluid">
    <div class="row">
      <div class="ss-form-item ss-auto-width">
        <label for="organization-country">Select WSHA to Assign Case</label>
        <select
          [(ngModel)]="selectedWSHA"
          class="form-control form-select ss-form-control shadow-none"
          name="organization-country"
          aria-label=""
        >
          <option [value]="" disabled selected>Select WSHA</option>
          <option *ngFor="let wsha of wshaList" [ngValue]="wsha">
            {{ wsha["first_name"] + " " + wsha["last_name"] }}
          </option>
        </select>
      </div>
    </div>

    <div class="row mt-3" *ngIf="selectedWSHA">
      <div class="form-secondary-text">Assigned WSHA</div>
      <div class="content-stat-devider mt-2"></div>
      <div class="d-flex flex-row justify-item-start align-items-center mt-3">
        <div
          class="col info-item d-flex flex-column justify-item-start align-items-start gap-1"
        >
          <div class="form-section-title">WSHA Name</div>
          <div class="form-secondary-text">
            {{
              selectedWSHA.first_name + " " + selectedWSHA.last_name || "N/A"
            }}
          </div>
        </div>
        <div
          class="col info-item d-flex flex-column justify-item-start align-items-start gap-1"
        >
          <div class="form-section-title">Email Address</div>
          <div class="form-secondary-text">
            {{ selectedWSHA.email_address || "N/A" }}
          </div>
        </div>
        <div
          class="col info-item d-flex flex-column justify-item-start align-items-start gap-1"
        >
          <div class="form-section-title">Contact Number</div>
          <div class="form-secondary-text">
            {{ selectedWSHA.contact_number || "N/A" }}
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="form-secondary-text">Case Details</div>
      <div class="content-stat-devider mt-2"></div>
      <div class="d-flex flex-row justify-item-start align-items-center mt-3">
        <div
          class="col info-item d-flex flex-column justify-item-start align-items-start gap-1"
        >
          <div class="form-section-title">Case ID</div>
          <div class="form-secondary-text">{{ rowData.case_id }}</div>
        </div>
        <div
          class="col info-item d-flex flex-column justify-item-start align-items-start gap-1"
        >
          <div class="form-section-title">Status</div>
          <div class="form-secondary-text">{{ rowData.status }}</div>
        </div>
        <div
          class="col info-item d-flex flex-column justify-item-start align-items-start gap-1"
        >
          <div class="form-section-title">Category</div>
          <div class="form-secondary-text">{{ rowData.case_category }}</div>
        </div>
        <div
          class="col info-item d-flex flex-column justify-item-start align-items-start gap-1"
        >
          <div class="form-section-title">Location</div>
          <div class="form-secondary-text">{{ rowData.case_location }}</div>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="d-flex flex-row justify-item-start align-items-center">
        <div
          *ngIf="rowData.case_type !== 'public'"
          class="col info-item d-flex flex-column justify-item-start align-items-start gap-1"
        >
          <div class="form-section-title">Case Deadline</div>
          <div class="form-secondary-text">{{ rowData.case_deadline }}</div>
        </div>
        <div
          class="col info-item d-flex flex-column justify-item-start align-items-start gap-1"
        >
          <div class="form-section-title">Created At</div>
          <div class="form-secondary-text">
            {{ utils.getSGTZoneDateTime(rowData.created_at) }}
          </div>
        </div>
        <div
          class="col-6 info-item d-flex flex-column justify-item-start align-items-start gap-1"
        >
          <div class="form-section-title">Description</div>
          <div class="form-secondary-text">
            {{ rowData.case_description || "NA" }}
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3" *ngIf="images.length > 0; else noMedia">
      <!-- <div style="width:314px; height:44px"> -->
      <ng-image-slider
        [images]="images"
        [imageSize]="imageSize"
        [manageImageRatio]="true"
        [showArrow]="false"
        [infinite]="true"
        [animationSpeed]="2"
        [autoSlide]="true"
        #nav
      ></ng-image-slider>
      <!--  </div>   -->
    </div>
    <ng-template #noMedia>
      <div>Case media is unavailable</div>
    </ng-template>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn ss-btn-secondary" (click)="onCancelClick()">
    Close
  </button>
  <button
    type="button"
    class="btn ss-btn-primary"
    (click)="onConfirmClick()"
    [disabled]="selectedWSHA == undefined"
  >
    Assign
  </button>
</div>
