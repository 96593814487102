import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AutorizationMainComponent } from './components/authorization/autorization-main/autorization-main.component';
import { DashboardMainComponent } from './components/dashboard/dashboard-main/dashboard-main.component';
import { RequestDeleteUserComponent } from './components/company-public/request-delete-user/request-delete-user.component';


const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/login',
  },
  {
    path: '',
    component: AutorizationMainComponent,
    loadChildren: () =>
      import('./components/authorization/authorization.module').then((m) => m.AuthorizationModule),
      canActivate: [],
  },
  {
    path: 'dashboard',
    // component: DashboardMainComponent,
    loadChildren: () =>
      import('./components/dashboard/dashboard.module').then((m) => m.DashboardModule),
      canActivate: [],
  },
  {
    path: 'cc-dashboard',
    // component: DashboardMainComponent,
    loadChildren: () =>
      import('./components/company-client-dashboard/company-client-dashboard.module').then((m) => m.CompanyClientDashboardModule),
      canActivate: [],
  },
  {
    path: 'delete-account-request',
    component: RequestDeleteUserComponent,
    loadChildren: () =>
      import('./components/company-public/company-public.module').then((m) => m.CompanyPublicModule),
      canActivate: [],
  },

    // Add the wildcard route to redirect to /login for any other routes
    {
      path: '**',
      redirectTo: '/login',
    },
];


  

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
