import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-plan-limit-alert',
  templateUrl: './plan-limit-alert.component.html',
  styleUrls: ['./plan-limit-alert.component.scss'],
})
export class PlanLimitAlertComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog
  ) {}

  dismissNotification() {
    this.dialog.closeAll();
  }
}
