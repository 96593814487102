// confirm-dialog.component.ts

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { HttpService } from '../../http/http.service';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { AssignCaseDialogService } from '../../assign-case-dialog/assign-case-dialog.service';


@Component({
  selector: 'app-assign-case-dialog',
  template: `
    <div class="modal-header">
      <h4 class="modal-title">Assign Case</h4>
      <div class="ss-form-close-icon" (click)="onCancelClick()">
          <img src="assets/images/ss-icons/form-cross.svg" alt="">
      </div>
    </div>
    <div class="modal-body">
        <ng-template #customItemTemplate let-model="item" let-index="index">
           <div>{{ model.first_name }} {{model.last_name}}</div>
        </ng-template>

        <pre class="card card-block card-header mb-3">Model: {{selectedWSHA | json}}</pre>
        <input [(ngModel)]="selectedWSHA"
               [typeahead]="wshaList"
               typeaheadOptionField="first_name"
               [typeaheadItemTemplate]="customItemTemplate"
               (typeaheadOnSelect)="onSelect($event)"
               class="form-control">
    </div>
    <div class="modal-footer">
      <button type="button" class="btn ss-btn-secondary" (click)="onCancelClick()">Cancel</button>
      <button type="button" class="btn ss-btn-primary" (click)="onConfirmClick()">Assign</button>
    </div>
  `,
})
export class AssignCaseDialogComponent implements OnInit{
  @Input() data: any;
  @Output() confirmEvent = new EventEmitter<any>();
  wshaList: any[] = [];
  selectedWSHA: any;
  selectedItem: any;

  confirmCallback: () => void = () => {};
  cancelCallback: () => void = () => {};

  constructor(public bsModalRef: BsModalRef, private http: HttpService, private assignCaseService: AssignCaseDialogService) {}
  ngOnInit(): void {
    this.wshaList = this.data;
  }

  onCancelClick(): void {
    this.bsModalRef.hide();
    this.cancelCallback();
  }

  onConfirmClick(): void {
    this.bsModalRef.hide();
    this.confirmCallback();
    this.assignCaseService.setSelectedWSHA(this.selectedItem);
  }

  onSelect(event: TypeaheadMatch): void {
    this.selectedItem = event.item;
  }
}


