<div class="modal-header">
  <h4 class="modal-title">
    HSEF Registry Details
  </h4>
  <div class="ss-form-close-icon" (click)="onCancelClick()">
    <img src="assets/images/ss-icons/form-cross.svg" alt="" />
  </div>
</div>
<div class="modal-body">
  <div class="container-fluid" *ngIf="selectedItem == undefined">
    <div class="row">
      There is something wrong, hence unable to fetch Case Details at present.
      Kindly contact Support Team.
    </div>
  </div>
  <div class="container-fluid" *ngIf="selectedItem">
    <div class="row">
      <div class="d-flex flex-column justify-content-center align-items-center">
        <div class="user-profile-pic">
          <p-image
            *ngIf="selectedItem[0].profile_picture !== null; else fallbackImage"
            [src]="selectedItem[0].imageUrl"
            width="150"
            alt="Image"
            [preview]="true"
          ></p-image>
          <!--  <img *ngIf="selectedItem[0].profile_picture !== null; else fallbackImage" [src]="selectedItem[0].imageUrl" alt="" class="profile-user-pic"> -->
          <ng-template #fallbackImage>
            <img
              src="assets/images/common/headshot-placeholder.webp"
              class="profile-user-pic"
              alt="employee-profile-pic"
            />
          </ng-template>
        </div>
        <div class="form-secondary-text mt-1">
          {{ selectedItem[0].hsef_name }}
        </div>
      </div>
      <div class="content-stat-devider mt-2"></div>
    </div>
    <div class="row mt-3">
      <div class="form-secondary-text">HSEF Details</div>
      <div class="content-stat-devider mt-2"></div>
      <div class="d-flex flex-row justify-item-start align-items-center mt-3">
        <div
          class="col info-item d-flex flex-column justify-item-start align-items-start gap-1"
        >
          <div class="form-section-title">Name</div>
          <div class="form-secondary-text">{{ selectedItem[0].hsef_name }}</div>
        </div>
        <div
          class="col info-item d-flex flex-column justify-item-start align-items-start gap-1"
        >
          <div class="form-section-title">Company</div>
          <div class="form-secondary-text">
            {{ selectedItem[0].company_name }}
          </div>
        </div>
        <div
          class="col info-item d-flex flex-column justify-item-start align-items-start gap-1"
        >
          <div class="form-section-title">Email</div>
          <div class="form-secondary-text">
            {{ selectedItem[0].hsef_email }}
          </div>
        </div>
        <div
          class="col info-item d-flex flex-column justify-item-start align-items-start gap-1"
        >
          <div class="form-section-title">Contact</div>
          <div class="form-secondary-text">
            {{ selectedItem[0].hsef_contact_number }}
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="d-flex flex-row justify-item-start align-items-center">
        <div
          class="col info-item d-flex flex-column justify-item-start align-items-start gap-1"
        >
          <div class="form-section-title">Nationality</div>
          <div class="form-secondary-text">
            {{ selectedItem[0].nationality }}
          </div>
        </div>
        <div
          class="col info-item d-flex flex-column justify-item-start align-items-start gap-1"
        >
          <div class="form-section-title">Present Industry</div>
          <div class="form-secondary-text">
            {{ selectedItem[0].present_industry_type }}
          </div>
        </div>
        <div
          class="col info-item d-flex flex-column justify-item-start align-items-start gap-1"
        >
          <div class="form-section-title">Preferred Industry</div>
          <div class="form-secondary-text">
            {{ selectedItem[0].preffered_industry_type }}
          </div>
        </div>
        <div
          class="col info-item d-flex flex-column justify-item-start align-items-start gap-1"
        >
          <div class="form-section-title">Academic Qualification</div>
          <div class="form-secondary-text">
            {{ selectedItem[0].academic_qualification }}
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="d-flex flex-row justify-item-start align-items-center">
        <div
          class="col-3 info-item d-flex flex-column justify-item-start align-items-start gap-1"
        >
          <div class="form-section-title">Years of Experiance</div>
          <div class="form-secondary-text">
            {{ selectedItem[0].total_experiance }}
          </div>
        </div>
        <div
          class="col-3 info-item d-flex flex-column justify-item-start align-items-start gap-1"
        >
          <div class="form-section-title">Created At</div>
          <div class="form-secondary-text">
            {{
              selectedItem[0].created_at == null
                ? "Unavailable"
                : utils.getSGTZoneDateTime(selectedItem[0].created_at)
            }}
          </div>
        </div>
        <div
          class="col-3 info-item d-flex flex-column justify-item-start align-items-start gap-1"
        >
          <div class="form-section-title">Badge</div>
          <div class="form-secondary-text">
            {{ selectedItem[0].badge == "true" ? "Yes" : "No Badge" }}
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <!-- <div class="form-secondary-text">
               Request Details
            </div> -->
      <div class="content-stat-devider mt-2"></div>
      <div class="d-flex flex-row justify-item-start align-items-center mt-3">
        <div
          class="col info-item d-flex flex-column justify-item-start align-items-start gap-1"
        >
          <div class="form-section-title">Verification Requested</div>
          <div class="form-secondary-text">
            {{ selectedItem[0].is_request_sent ? "Yes" : "No" }}
          </div>
        </div>
       <!--  <div
          class="col info-item d-flex flex-column justify-item-start align-items-start gap-1"
        >
          <div class="form-section-title">Verification Status</div>
          <div class="form-secondary-text">
            {{
              selectedItem[0].verification_status ? "Yet to verify" : "Verified"
            }}
          </div>
        </div> -->
        <!-- <div class="col info-item d-flex flex-column justify-item-start align-items-start gap-1">
                    <div class="form-section-title">Verified At</div>
                    <div class="form-secondary-text">{{selectedItem[0].verified_at == null? 'Unavailable': utils.getSGTZoneDateTime(selectedItem[0].verified_at) }}</div>
                </div>
                <div class="col info-item d-flex flex-column justify-item-start align-items-start gap-1">
                    <div class="form-section-title">Verified By</div>
                    <div class="form-secondary-text">{{selectedItem[0].verified_by_email == null? 'Unavailable': selectedItem[0].verified_by_email}}</div>
                </div> -->
      </div>
    </div>
  </div>
</div>
