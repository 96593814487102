// side-panel.service.ts

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidePanelService {
  private isOpenSubject = new BehaviorSubject<boolean>(false);
  isOpen$ = this.isOpenSubject.asObservable();
  
  panelOpen() {
    this.isOpenSubject.next(true);
  }
  panelClose() {
    this.isOpenSubject.next(false);
  }
}
