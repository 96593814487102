// confirm-dialog.service.ts

import { Injectable } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Observable, Subject } from 'rxjs';
import { CaseInfoModalComponent } from './case-info-modal/case-info-modal.component';


@Injectable({
    providedIn: 'root',
  })
  export class CaseInfoModalService {
    private modalRef: BsModalRef | undefined;
    private selectedRowData$ = new Subject<any>();

  constructor(private modalService: BsModalService) {}
  open(data: any): Observable<any> {
    const initialState = { data, selectedRowData$: this.selectedRowData$};
    this.modalRef = this.modalService.show(CaseInfoModalComponent, {
      class: 'modal-dialog-centered modal-lg',
      initialState,
    });

    return this.selectedRowData$.asObservable();
  }

  close(): void {
    if (this.modalRef) {
      this.modalRef.hide();
    }
  }

  setSelectedRowData(selectedWSHA: any): void {
    this.selectedRowData$.next(selectedWSHA);
  }
  }
