import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { HttpService } from '../../http/http.service';
import { CaseInfoModalService } from '../case-info-modal.service';
import { UtilsService } from '../../utils/utilis.service';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-case-info-modal',
  templateUrl: './case-info-modal.component.html',
  styleUrls: ['./case-info-modal.component.scss'],
})
export class CaseInfoModalComponent {
  @Input() data: any;
  // @Output() confirmEvent = new EventEmitter<any>();
  wshaList: any[] = [];
  selectedWSHA: any;
  selectedItem: any;
  // slide options
  imageSize = {
    width: '44px',
    height: '144px',
    space: 1,
  };
  images: any = []; /* [
    {image:"http://62.72.56.38:3001/uploads/1704428208772-562103398.png", thumbImage:"http://62.72.56.38:3001/uploads/1704428208772-562103398.png"},
    {image:"http://62.72.56.38:3001/uploads/1704660845666-28607259.jpg", thumbImage:"http://62.72.56.38:3001/uploads/1704660845666-28607259.jpg"}
  ] */

  resolvedCaseMedia: any = [];
  inprogressCaseMedia: any = [];
  rejectCaseMedia: any = [];

  confirmCallback: () => void = () => {};
  cancelCallback: () => void = () => {};

  constructor(
    public bsModalRef: BsModalRef,
    private http: HttpService,
    private caseDetailsModalService: CaseInfoModalService,
    public utils: UtilsService
  ) {}
  ngOnInit(): void {
    this.selectedItem = this.data;
    this.images = this.utils.buildImageArray(this.selectedItem[0]?.case_media);
    const resolvedStateObject = this.selectedItem.filter((obj: any) => {
      return obj.t_status === 'resolved';
    });
    const inprogressStateObject = this.selectedItem.filter((obj: any) => {
      return obj.t_status === 'inprogress';
    });
    const rejectStateObject = this.selectedItem.filter((obj: any) => {
      return obj.t_status === 'reject';
    });
    this.resolvedCaseMedia = this.utils.buildImageArray(
      resolvedStateObject[0].t_resolved_case_media
    );
    this.inprogressCaseMedia = this.utils.buildImageArray(
      inprogressStateObject[0].t_resolved_case_media
    );
    this.rejectCaseMedia = this.utils.buildImageArray(
      rejectStateObject[0].t_resolved_case_media
    );
  }

  onCancelClick(): void {
    this.bsModalRef.hide();
  }

  DownloadDetails(caseid: any) {
    let data = document.getElementById('content')!;
    html2canvas(data, {
      allowTaint: true,
      useCORS: true,
    }).then((canvas) => {
      // Few necessary setting options
      var imgWidth = 208;
      var pageHeight = 275;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/jpeg');
      let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF
      pdf.addImage(contentDataURL, 'JPEG', 5, 5, imgWidth, imgHeight);
      pdf.save(`case_${caseid}.pdf`); // Generated PDF
    });
  }
}
