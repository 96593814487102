import { Component } from '@angular/core';

@Component({
  selector: 'app-autorization-main',
  templateUrl: './autorization-main.component.html',
  styleUrls: ['./autorization-main.component.scss']
})
export class AutorizationMainComponent {

}
