import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { HttpService } from '../http/http.service';

import { UtilsService } from '../utils/utilis.service';
import { TBMInfoModalService } from './tbminfo-modal.service';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-tbm-info-modal',
  templateUrl: './tbm-info-modal.component.html',
  styleUrls: ['./tbm-info-modal.component.scss'],
})
export class TbmInfoModalComponent {
  @Input() data: any;
  selectedItem: any;
  imageSize = {
    width: '44px',
    height: '144px',
    space: 3,
  };
  images: any = [];

  confirmCallback: () => void = () => {};
  cancelCallback: () => void = () => {};

  constructor(
    public bsModalRef: BsModalRef,
    private http: HttpService,
    private tbmDetailsModalService: TBMInfoModalService,
    public utils: UtilsService
  ) {}
  ngOnInit(): void {
    this.selectedItem = this.data;
    this.images = this.utils.buildImageArray(
      this.selectedItem[0]['TBM'][0]?.image_attachment
    );
  }

  onCancelClick(): void {
    this.bsModalRef.hide();
  }

  DownloadDetails() {
    let data = document.getElementById('content')!;
    let unique = new Date().valueOf();
    html2canvas(data, {
      allowTaint: true,
      useCORS: true,
    }).then((canvas) => {
      // Few necessary setting options
      var imgWidth = 208;
      var pageHeight = 275;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/jpeg');
      let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF
      pdf.addImage(contentDataURL, 'JPEG', 5, 5, imgWidth, imgHeight);
      pdf.save(`tbm_${unique}.pdf`); // Generated PDF
    });
  }
}
