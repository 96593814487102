import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { HttpService } from '../../http/http.service';
//import { CaseInfoModalService } from '../case-info-modal.service';
import { UtilsService } from '../../utils/utilis.service';
import { AssignCaseDialogService } from '../assign-case-dialog.service';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';

@Component({
  selector: 'app-assign-case',
  templateUrl: './assign-case.component.html',
  styleUrls: ['./assign-case.component.scss']
})
export class AssignCaseComponent {
  @Input() data: any;
  @Input() rowData: any;
  @Output() confirmEvent = new EventEmitter<any>();
  wshaList: any[] = [];
  selectedWSHA: any;
  selectedItem: any;

  imageSize = {
    width:"54px",
    height:"49px",
    space: 1
  }
  images: any = [];

  confirmCallback: () => void = () => {};
  cancelCallback: () => void = () => {};

  constructor(public bsModalRef: BsModalRef, 
              private http: HttpService, 
              private assignCaseService: AssignCaseDialogService,
              public utils: UtilsService) {}
  ngOnInit(): void {
    console.log("INPUT DATA in modal component >>>> ", this.data);
    this.images = this.utils.buildImageArray(this.rowData?.case_media);
    this.wshaList = this.data;
  }

  onCancelClick(): void {
    this.bsModalRef.hide();
  }

  onConfirmClick(): void {
    this.bsModalRef.hide();
    this.confirmCallback();
    this.assignCaseService.setSelectedWSHA(this.selectedWSHA);
  }

  onSelect(event: TypeaheadMatch): void {
    this.selectedItem = event.item;
  }
}
