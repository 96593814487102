// active-menu.service.ts

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ActiveMenuService {
  private activeMenuSubject = new BehaviorSubject<string>(''); // Default active route is empty
  activeMenu$ = this.activeMenuSubject.asObservable();

  setActiveMenu(route: string) {
    this.activeMenuSubject.next(route);
  }
}
