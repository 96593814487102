<div class="modal-header">
  <h4 class="modal-title">Toolbox Meeting Details</h4>
  <div class="ss-form-close-icon d-flex" style="gap: 15px">
    <div
      (click)="DownloadDetails()"
      class="ss-form-close-icon"
      style="cursor: pointer"
    >
      <i class="fas fa-download"></i>
    </div>
    <div class="ss-form-close-icon" (click)="onCancelClick()">
      <img src="assets/images/ss-icons/form-cross.svg" alt="" />
    </div>
  </div>
</div>
<div class="modal-body">
  <div class="container-fluid" *ngIf="selectedItem == undefined">
    <div class="row">
      There is something wrong, hence unable to fetch Toolbox Meeting Details at
      present. Kindly contact Support Team.
    </div>
  </div>
  <div class="container-fluid" id="content" *ngIf="selectedItem">
    <div class="row">
      <!--  <div class="form-secondary-text">Toolbox Meeting Details</div> -->
      <div class="content-stat-devider mt-2"></div>
      <div class="d-flex flex-row justify-item-start align-items-center mt-3">
        <div
          class="col info-item d-flex flex-column justify-item-start align-items-start gap-1"
        >
          <div class="form-section-title">Meeting ID</div>
          <div class="form-secondary-text">
            {{ selectedItem[0]["TBM"][0]?.meeting_id }}
          </div>
        </div>
       <!--  <div
          class="col info-item d-flex flex-column justify-item-start align-items-start gap-1">
          <div class="form-section-title">Meeting Organiser ID</div>
          <div class="form-secondary-text">
            {{ selectedItem[0]["TBM"][0]?.meeting_organiser_id }}
          </div>
        </div> -->
        <div
          class="col info-item d-flex flex-column justify-item-start align-items-start gap-1"
        >
          <div class="form-section-title">Location</div>
          <div class="form-secondary-text">
            {{ selectedItem[0]["TBM"][0]?.location }}
          </div>
        </div>
        <div
          class="col info-item d-flex flex-column justify-item-start align-items-start gap-1"
        >
          <div class="form-section-title">Date and Time</div>
          <div class="form-secondary-text">
            {{ utils.getSGTZoneDateTime(selectedItem[0]["TBM"][0]?.date_time) }}
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-3">
      <div class="d-flex flex-row justify-item-start align-items-center">
        <div
          class="col info-item d-flex flex-column justify-item-start align-items-start gap-1"
        >
          <div class="form-section-title">Appointed Personnel</div>
          <div class="form-secondary-text">
            {{ selectedItem[0]["TBM"][0]?.meeting_organiser_name }}
          </div>
        </div>
        <div
          class="col info-item d-flex flex-column justify-item-start align-items-start gap-1"
        >
          <div class="form-section-title">Appointed Personnel Email</div>
          <div class="form-secondary-text">
            {{ selectedItem[0]["TBM"][0]?.meeting_organiser_email }}
          </div>
        </div>
        <div
          class="col info-item d-flex flex-column justify-item-start align-items-start gap-1"
        >
          <!-- <div class="form-section-title">Organiser Company Name</div>
          <div class="form-secondary-text">
            {{ selectedItem[0]["TBM"][0]?.meeting_organiser_company ? selectedItem[0]["TBM"][0]?.meeting_organiser_company : 'N/A'}}
          </div> -->
        </div>
      </div>
    </div>

    <div class="row mt-3">
      <div class="d-flex flex-row justify-item-start align-items-center">
        <div
          class="col info-item d-flex flex-column justify-item-start align-items-start gap-1"
        >
          <div class="form-section-title">Description</div>
          <div class="form-secondary-text">
            {{ selectedItem[0]["TBM"][0]?.description_notes }}
          </div>
        </div>
        <div
          class="col info-item d-flex flex-column justify-item-start align-items-start gap-1"
        >
          <div class="form-section-title">No of workers</div>
          <div class="form-secondary-text">
            {{ selectedItem[0]["TBM"][0]?.number_of_workers }}
          </div>
        </div>
        <div
          class="col info-item d-flex flex-column justify-item-start align-items-start gap-1"
        >
          <div class="form-section-title">Hazards</div>
          <div class="form-secondary-text">
            {{ selectedItem[0]["TBM"][0]?.list_of_hazards }}
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <!-- <div
        class="col-6 info-item d-flex flex-column justify-item-start align-items-start gap-1">
        <div class="form-section-title">Worksite ID</div>
        <div class="form-secondary-text">
          {{ selectedItem[0]["TBM"][0]?.worksite_id }}
        </div>
      </div> -->
      <div
        class="col-4 info-item d-flex flex-column justify-item-start align-items-start gap-1"
      >
        <div class="form-section-title">Worksite Name</div>
        <div class="form-secondary-text">
          {{ selectedItem[0]["TBM"][0]?.worksite_name }}
        </div>
      </div>
      <div
        class="col-4 info-item d-flex flex-column justify-item-start align-items-start gap-1"
      >
        <div class="form-section-title">Key Personnel</div>
        <div class="form-secondary-text">
          {{ selectedItem[0]["TBM"][0]?.key_personnel }}
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <ng-image-slider
        [images]="images"
        [imageSize]="imageSize"
        [manageImageRatio]="true"
        #nav
      ></ng-image-slider>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn ss-btn-secondary" (click)="onCancelClick()">
    Close
  </button>
  <!--  <button type="button" class="btn ss-btn-primary" (click)="onConfirmClick()">Assign</button> -->
</div>
