
<!-- <header class="custom-header">
    <div class="header-content">
        <h4 class="pt-3 mb-0">Business Operations</h4>
        <p>Performance Management</p>
    </div>
    <div class="logo">
        <img src="your-logo.png" alt="Logo" class="img-fluid">
    </div>
  </header>
<header class="greetings">
    <div class="header-content">
        <h4 class="pt-3 mb-0">Welcome Lorem Ipsum</h4>
        <p>Personal care</p>
    </div>
  </header> -->
<router-outlet></router-outlet>
<app-loader></app-loader>