// confirm-dialog.component.ts

import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';


@Component({
  selector: 'app-confirm-dialog',
  template: `
    <div class="modal-header">
      <h4 class="modal-title">Confirmation</h4>
      <div class="ss-form-close-icon" (click)="onCancelClick()">
          <img src="assets/images/ss-icons/form-cross.svg" alt="">
      </div>
     <!--  <button type="button" class="close" aria-label="Close" >
        <span aria-hidden="true">&times;</span>
      </button> -->
    </div>
    <div class="modal-body">
      {{ message }}
    </div>
    <div class="modal-footer">
      <button type="button" class="btn ss-btn-secondary" (click)="onCancelClick()">Cancel</button>
      <button type="button" class="btn ss-btn-primary" (click)="onConfirmClick()">Confirm</button>
    </div>
  `,
})
export class ConfirmDialogComponent {
  @Input() message: string = '';
  confirmCallback: () => void = () => {};
  cancelCallback: () => void = () => {};

  constructor(public bsModalRef: BsModalRef) {}

  onCancelClick(): void {
    this.bsModalRef.hide();
    this.cancelCallback();
  }

  onConfirmClick(): void {
    this.bsModalRef.hide();
    this.confirmCallback();
  }
}


