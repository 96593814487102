import { Injectable } from '@angular/core';
import { AngularFireMessaging} from '@angular/fire/compat/messaging';
import { HttpService } from '../http/http.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})


export class FcmService {

  nofificationMessage: BehaviorSubject<any> = new BehaviorSubject([]);
  notificationState: BehaviorSubject<boolean> = new BehaviorSubject(false);
  notificationState$ = this.notificationState.asObservable();
  constructor(private angularFireMessaging: AngularFireMessaging, private http: HttpService) {
    this.angularFireMessaging.onMessage((message) => {
      console.log('Message received:', message);
      // Handle the received message here
    });
  }

  requestPermission(userId: any) {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        // Send the token to your server to associate it with the user
        console.log("FCM Token >>>> ", token);
        this.http.post('register-device-token', {user_id:userId, device_token: token, platform: 'web'}).subscribe((res)=>{}, (err)=>{
           console.log("Error in register device token in DB from Web Portal");
        });
      },
      (error) => {
        console.error('Unable to get device token From FCM >>> ', error);
      }
    );
  }

  receiveMessages() {
    let notification: any = [];
    this.angularFireMessaging.messages.subscribe((message) => {
      console.log("FCM Message has been recieved >>>> ", message);
      notification.push(message);
      this.nofificationMessage.next(notification);
      this.notificationState.next(true);
    });
  }

  resetBehaviorSubject() {
    // Reset the BehaviorSubject in the service
    this.nofificationMessage.next([]);
    this.notificationState.next(false);
  }
}
